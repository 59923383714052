import React from "react"
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';

const GAME_STATES = {
    active: 'active',
    over: 'over'
}

function GameDisplay({ low, high, words }) {
    return (
        <Container>
            <Row>
                <Col>
                    <p>Guess a word between...</p>
                </Col>
            </Row>
            <h3>"{words[low]}"</h3>
            <p>and...</p>
            <h3>"{words[high]}"</h3>
        </Container>
    )
}

function GameInputs({
    handleGiveUp,
    handleGuess,
    handleGuessUpdate,
    pendingGuess,
    remainingTries
}) {
    return (
        <Container>
            <Row>
                <Col>
                    <Form onSubmit={handleGuess}>
                        <Form.Control
                            onChange={handleGuessUpdate}
                            placeholder="frankleton"
                            value={pendingGuess} />
                        <Form.Text className="text-muted">
                            Tries remaining: {remainingTries}
                        </Form.Text>
                        <br />
                        <Button type="submit" value="guess">Guess</Button>
                        <Button onClick={handleGiveUp} variant="link">Give up</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

class Game extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            gameState: GAME_STATES.active,
            guesses: [],
            low: 0,
            message: 'Good luck!',
            pendingGuess: '',
            remainingTries: 5,
            words: []
        }

        this.handleGuessUpdate = this.handleGuessUpdate.bind(this)
        this.handleGuess = this.handleGuess.bind(this)
        this.handleGiveUp = this.handleGiveUp.bind(this)
        this.handleTryAgain = this.handleTryAgain.bind(this)
    }

    componentDidMount() {
        fetch('./words_alpha.txt')
            .then((response) => {
                return response.text()
            })
            .then((response) => {
                const words = response.split('\n').sort().filter((word) => word !== '')
                this.setState({
                    words,
                    high: words.length - 1
                })
            })
    }

    handleGiveUp() {
        const {
            high,
            low,
            words
        } = this.state
        const n = high - low - 1
        let randomRemainingWords = []

        for (let i = 0; i < 3; i++) {
            randomRemainingWords.push(words[low + Math.floor(Math.random() * (n - 1) + 1)])
        }
        randomRemainingWords = randomRemainingWords.sort()
        this.setState({
            gameState: GAME_STATES.over,
            guesses: [],
            message: `nice try, there were ${n} words remaining. words like... ${randomRemainingWords[0]}, ${randomRemainingWords[1]}, or ${randomRemainingWords[2]}`
        })
    }

    handleGuessUpdate(e) {
        this.setState({
            pendingGuess: e.target.value.toLocaleLowerCase()
        })
    }

    handleGuess(e) {
        e.preventDefault();
        const {
            guesses,
            high,
            low,
            pendingGuess: guess,
            words
        } = this.state
        const index = words.indexOf(guess)

        guesses.push(guess)
        this.setState({
            guesses
        })

        if (words.indexOf(guess) === -1) {
            const nextTries = this.state.remainingTries - 1

            if (nextTries === 0) {
                this.setState({
                    gameState: GAME_STATES.over,
                    guesses: [],
                    message: `Sorry, ${guess} is not a word, out of tries :(`
                })
            } else {
                this.setState({
                    remainingTries: nextTries,
                    message: `Sorry, ${guess} is not a word`
                })
            }

            // real word but out of bounds
        } else if (index <= low || index >= high) {
            this.setState({
                message: `guess must be between "${words[low]}" and "${words[high]}", try again`,
                pendingGuess: ''
            })

            // good guess
        } else {
            // set the new range to the larger of the possible ranges between low..index..high
            if ((index - low) > (high - index)) {
                this.setState({
                    high: index
                })
            } else {
                this.setState({
                    low: index
                })
            }

            if ((high - low) === 1) {
                this.setState({
                    message: `congrats! you win, there are no more words between "${words[low]}" and "${words[high]}"`,
                    remainingTries: 0
                })
            } else {
                this.setState({
                    message: 'good job, lets make this harder',
                    pendingGuess: ''
                })
            }
        }
    }

    handleTryAgain() {
        this.setState({
            gameState: GAME_STATES.active,
            guesses: [],
            high: this.state.words.length - 1,
            low: 0,
            message: 'Good luck!',
            pendingGuess: '',
            remainingTries: 5
        })
    }

    render() {
        return (
            <Container>
                {this.state.gameState === GAME_STATES.active ? (
                    <GameDisplay
                        low={this.state.low}
                        high={this.state.high}
                        words={this.state.words} />
                ) : null}
                <p>{this.state.message}</p>
                {this.state.gameState === GAME_STATES.active ? (
                    <GameInputs
                        handleGiveUp={this.handleGiveUp}
                        handleGuess={this.handleGuess}
                        handleGuessUpdate={this.handleGuessUpdate}
                        pendingGuess={this.state.pendingGuess}
                        remainingTries={this.state.remainingTries} />
                ) : null}
                <br />
                <Row>
                    <Col>
                        <ListGroup>
                            {this.state.guesses.slice().reverse().map((guess, i) => {
                                return (
                                    <ListGroup.Item key={i}>{guess}</ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                    </Col>
                </Row>
                {this.state.gameState === GAME_STATES.over ? (
                    <Button onClick={this.handleTryAgain}>Try again</Button>
                ) : null}
            </Container>
        )
    }
}

export default Game
