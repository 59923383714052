import './App.css';
import Navbar from 'react-bootstrap/Navbar';
import Game from './game.js';

function App() {
  return (
    <div className="App">
      <Navbar bg="light">
        <Navbar.Brand href="#home">Which Word Works</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
      </Navbar>
      <Game/>
    </div>
  );
}

export default App;
